<template>
	<TableLayout
        :module="module"
        ref="TableLayout"
        :form-initial="form_initial"
        :form-rules="formRules"
        :rights="rights"
        :table-columns="tableColumns"
        v-if="(user && user.rights)"
        initSortField="date"
        initSortOrder="desc"
        :filters="filters"
        addButtonName="Add Holiday Date">

        <template v-slot:form_items="{formdata}">
    		<el-form-item label="Holiday Date" prop="date">
                <el-date-picker
                    v-model="formdata.date"
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    :clearable="false">
                </el-date-picker>
            </el-form-item>
        </template>

    </TableLayout>
</template>

<script>
import { mapState } from 'vuex'
import TableLayout from '@/components/TableLayout'
import tab from '@/mixins/tab'

export default {
    name: 'dict_holidays',
    mixins: [tab],
    components: { TableLayout },

    data() {
        return {
        	module: 'dictionaryHolidays',
            form_initial: {},
            formRules: {
            	date: [
                    { required: true, message: 'this field is required', trigger: 'blur' },
                ]
            },
            tableColumns: [
                {
                    field    : 'date', 
                    title    : 'Holiday Date', 
                    minWidth : 200
                },
            ],
            filters: {}
        }
    },

    computed: {
    	...mapState({
    		user: state => state.app.user,
    	}),

    	rights(){
    		return {
				view : (this.user && this.user.rights) ? this.user.rights['get-dict']   : false,
                edit : (this.user && this.user.rights) ? this.user.rights['edit-dict']  : false,
                add  : (this.user && this.user.rights) ? this.user.rights['add-dict']   : false,
                del  : (this.user && this.user.rights) ? this.user.rights['delete-dict']: false,
    		}
    	}
    },
}
</script>
